/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Text, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="pb--50 pl--0 pt--80" name={"uvod"}>
          
          <ColumnWrap className="column__flex el--1 pb--40 pl--40 pr--40 pt--60 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--center mt--10 pl--0 pr--0">
              
              <Title className="title-box fs--62 w--300 ls--002 lh--14" style={{"maxWidth":1082}} content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--30" name={"npc0oiamnnl"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 pb--40 pl--40 pr--40 pt--30" anim={"2"} animS={"2"} style={{"backgroundColor":"var(--color-custom-2)"}} columns={"1"}>
            
            <ColumnWrapper className="pb--40 pl--40 pr--40 pt--40">
              
              <ContactForm className="--shape2 fs--20" style={{"maxWidth":1682}} action={"/contact"} fields={[{"name":"Pełne imię","type":"text","required":true,"placeholder":"Wpisz pełne imię"},{"name":"Kontakt e-mail","type":"email","required":true,"placeholder":"Wpisz swój e-mail"},{"name":"Numer telefonu","type":"text","placeholder":"Telefon +480"},{"name":"Wiadomość","type":"textarea","required":true,"placeholder":"Wpisz tekst wiadomości"},{"name":"Wyślij","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pl--0 pt--50" name={"stopka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--3 pb--40 pt--40 flex--bottom" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Merbau"}>
              </Title>

              <Text className="text-box text-box--left fs--16" style={{"maxWidth":351}} content={"Produkujemy drewniane meble w centrum Wrocławia z najwyższej jakości naturalnych surowców."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--left" content={"Pańska 18"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"Warszawa, Miasto Warszawa"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"+48 797 811 2X0"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--hvr2 btn-box--sbtn1 btn-box--cColor2 btn-box--filling2 fs--18" href={"#uvod"} content={"<span style=\"color: rgb(255, 255, 255);\">Powrót do góry</span>"}>
              </Button>

              <Text className="text-box text-box--right mt--0" content={"<a href=\"https://saywebpage.com\">Tworzenie stron</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}